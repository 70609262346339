<template>
  <div id="app" @click="
    deselectIcon($event);
  applyAudio();">

    <div ref="container">
      <div class="desktop" @click="emptyClick" :style="{ backgroundImage: `url(${backgroundImage})` }"
        v-if="imageLoaded">
        <div v-if="isLoading">
          <!-- <ScreenLoader @loaderDone="conditionLoader++" /> -->
          <div class="window loader-window">
            <div class="window-body">
              <img src="../src/assets/logo.png" alt="Logo" class="logo-loader" />
              <p>Loading...</p>
              <div class="loader-bars">
                <div v-for="index in Math.ceil(progress / (100 / 16))" :key="index" class="loader-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <!-- Desktop icons go here -->
          <div class="icon-row">
            <div class="icon" :class="{ selected: selectedIcon === 1 }" @click="toggleIcon(1)" @dblclick="openGallery">
              <img src="../src/assets/icons/recycle_bin_full.png" alt="Icon 1" />
              <span>Recycle Bin</span>
            </div>
            <!-- Add more icons here -->
          </div>
          <div class="container">
            <img src="../src/assets/logo.png" alt="Logo" class="logo" />
          </div>
        </div>
      </div>

      <ParentPopUp v-if="!isLoading" @click="emptyClick" :enable-audio="audioValue" :dataset="defaultPositions"
        :playAudio="playAudio" />

      <ContactForm v-show="isContactForm" @close="toggleContactForm" :initial-position="formPosition" zIndex="999"
        isInactive="false" />

      <GalleryWindow v-show="isGallery" @close="toggleGallery" @open-picture="addPic" title="Recycle Bin"
        :initial-position="galleryPosition" zIndex="999" isInactive="false" />

      <GalleryPictureViewer v-show="checkPics" v-for="(pic, index) in galleryPics" :image-id="index" :key="index"
        :title="pic.title" :imageUrl="pic.imgUrl"
        :initial-position="{ x: viewPosition.x + index * 12, y: viewPosition.y + index * 12 }" zIndex="1000"
        :isInactive="pic.IsInactive" @close="closePopup(pic.id)" @changeFocus="setFocus(pic)" />


      <MainTaskBar v-if="!isLoading" @contact-button-clicked="toggleContactForm" @start-button-clicked="toggleStartMenu"
        :startMenu="isStartMenu" />
    </div>
  </div>
</template>

<script>
import ParentPopUp from "./components/ParentPopUp.vue";
import MainTaskBar from "./components/MainTaskBar.vue";
import ContactForm from "./components/ContactForm.vue";
import GalleryWindow from "./components/GalleryWindow.vue";
import GalleryPictureViewer from "./components/GalleryPictureViewer.vue";
// import ScreenLoader from "./components/ScreenLoader.vue";

import image1 from './assets/pop-ups/1.png';
import image2 from './assets/pop-ups/2.png';
import image3 from './assets/pop-ups/3.gif';
import image4 from './assets/pop-ups/4.png';
import image5 from './assets/pop-ups/5.png';
import image6 from './assets/pop-ups/6.png';
import image7 from './assets/pop-ups/7.png';
import image8 from './assets/pop-ups/8.png';
import image9 from './assets/pop-ups/9.gif';
import image10 from './assets/pop-ups/10.png';
import image11 from './assets/pop-ups/11.png';
import image12 from './assets/pop-ups/12.png';
import image13 from './assets/pop-ups/13.png';
import image14 from './assets/pop-ups/14.gif';
import image15 from './assets/pop-ups/15.gif';
import image16 from './assets/pop-ups/16.png';
import image17 from './assets/pop-ups/17.png';
import image18 from './assets/pop-ups/18.png';
import image19 from './assets/pop-ups/19.png';
import image20 from './assets/pop-ups/20.png';
//import image21 from './assets/pop-ups/21.gif';
import image21 from './assets/pop-ups/21.png';
import image22 from './assets/pop-ups/22.png';
import image23 from './assets/pop-ups/23.png';
import image24 from './assets/pop-ups/24.gif';

import bgImage from './assets/clouds.jpg';

import soundPop from './assets/sound/notify.mp3'; // Import the sound file


export default {
  components: {
    ParentPopUp,
    MainTaskBar,
    ContactForm,
    GalleryWindow,
    GalleryPictureViewer
  },
  data() {
    return {
      audio: null,
      formPosition: { x: 0, y: 0 },
      galleryPosition: { x: 400, y: 300 },
      viewPosition: { x: 690, y: 120 },
      selectedIcon: null,
      audioValue: 0,
      isLoading: true,
      imagesReady: false,
      resizeTimer: null,
      sourceResolutionX: 1920,
      sourceResolutionY: 1080,
      backgroundImage: bgImage,
      imageLoaded: false,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      isStartMenu: false,
      isContactForm: false,
      isGallery: false,
      totalImages: 0,
      loadedImages: 0,
      images: [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
        image13,
        image14,
        image15,
        image16,
        image17,
        image18,
        image19,
        image20,
        image21,
        image22,
        image23,
        image24
      ],
      preloadedImages: [],
      defaultPositions: [],
      galleryPics: []
    };
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    //  this.preloadedImages.forEach(image => {
    //   image.removeEventListener('load', this.handleImageLoad);
    // });
    document.removeEventListener('touchstart', this.handleTouchStart);
  },
  mounted() {

    document.addEventListener('touchstart', this.handleTouchStart, { passive: false });

    // if (navigator.userAgent.match(/iPhone|iPad/i)) {
    //     // iOS hides Safari address bar
    //     window.addEventListener("load", function() {
    //         setTimeout(function() {
    //             window.scrollTo(0, 1);
    //         }, 2500);
    //     });
    // }

    //this.callLoader();
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    // const aspectRatio = this.screenWidth / this.screenHeight;
    const img = new Image();
    img.onload = () => {
      this.imageLoaded = true;
    };
    img.src = this.backgroundImage;
    window.addEventListener('resize', this.handleResize);

    this.preloadSound();
    this.preloadImages(() => {


      //this.totalImages = this.preloadedImages.length;
      // Add event listeners to track image loading progress
      // this.preloadedImages.forEach(image => {
      //   image.addEventListener('load', this.handleImageLoad);
      // });

      if (this.screenWidth > 1024) {
        this.formPosition.x = window.innerWidth / 3;
        this.formPosition.y = 240;
        this.defaultPositions = [
          { "id": 1, "reSize": "480", "position": { "x": 27, "y": 28 }, "dynamicImageUrl": this.preloadedImages[0].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 2, "reSize": "480", "position": { "x": 484, "y": 409 }, "dynamicImageUrl": this.preloadedImages[1].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 3, "reSize": "480", "position": { "x": 659, "y": 27 }, "dynamicImageUrl": this.preloadedImages[2].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 4, "reSize": "480", "position": { "x": 76, "y": 9 }, "dynamicImageUrl": this.preloadedImages[3].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Your Horse Awaits", "show": false, "linkable": "", "call": true },
          { "id": 5, "reSize": "480", "position": { "x": 1164, "y": 0 }, "dynamicImageUrl": this.preloadedImages[4].src, "title": "Magic In Every Box! - Heminger`s Box - One Is All It Takes", "show": false, "linkable": "", "call": false },
          { "id": 6, "reSize": "480", "position": { "x": 1225, "y": 79 }, "dynamicImageUrl": this.preloadedImages[5].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Dare To Try?", "show": false, "linkable": "", "call": true },
          { "id": 7, "reSize": "480", "position": { "x": 1314, "y": 0 }, "dynamicImageUrl": this.preloadedImages[6].src, "title": "CALL NOW! - Going Somewhere? - Thiermann Travel", "show": false, "linkable": "", "call": false },
          { "id": 8, "reSize": "480", "position": { "x": 192, "y": 465 }, "dynamicImageUrl": this.preloadedImages[7].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 9, "reSize": "480", "position": { "x": 1408, "y": 24 }, "dynamicImageUrl": this.preloadedImages[8].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 10, "reSize": "480", "position": { "x": 40, "y": 215 }, "dynamicImageUrl": this.preloadedImages[9].src, "title": "Close In 5 Minutes! - Marns For Men - Pants and Other Essentials!", "show": false, "linkable": "", "call": false },
          { "id": 11, "reSize": "480", "position": { "x": 1430, "y": 298 }, "dynamicImageUrl": this.preloadedImages[10].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 12, "reSize": "480", "position": { "x": 840, "y": 324 }, "dynamicImageUrl": this.preloadedImages[11].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 13, "reSize": "480", "position": { "x": 524, "y": 570 }, "dynamicImageUrl": this.preloadedImages[12].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 14, "reSize": "480", "position": { "x": 28, "y": 598 }, "dynamicImageUrl": this.preloadedImages[13].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I Want You", "show": false, "linkable": "", "call": true },
          { "id": 15, "reSize": "480", "position": { "x": 693, "y": 310 }, "dynamicImageUrl": this.preloadedImages[14].src, "title": "BUY NOW! - Vulcomp - All New Vulcomp MK5 - NEW!", "show": false, "linkable": "https://www.vulcomp.com/" },
          { "id": 16, "reSize": "164", "position": { "x": 1732, "y": 546 }, "dynamicImageUrl": this.preloadedImages[15].src, "title": "Just One Drop! - Hart`s Wonder Elixir - Dare To Try?", "show": false, "linkable": "", "call": false },
          { "id": 17, "reSize": "480", "position": { "x": 1020, "y": 604 }, "dynamicImageUrl": this.preloadedImages[16].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Explore Now!", "show": false, "linkable": "", "call": true },
          { "id": 18, "reSize": "480", "position": { "x": 1152, "y": 522 }, "dynamicImageUrl": this.preloadedImages[17].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Come Join Us", "show": false, "linkable": "", "call": true },
          { "id": 19, "reSize": "480", "position": { "x": 1017, "y": 394 }, "dynamicImageUrl": this.preloadedImages[18].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 20, "reSize": "200", "position": { "x": 1512, "y": 550 }, "dynamicImageUrl": this.preloadedImages[19].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 21, "reSize": "480", "position": { "x": 245, "y": 525 }, "dynamicImageUrl": this.preloadedImages[20].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I`m On The Line", "show": false, "linkable": "", "call": true },
          { "id": 22, "reSize": "480", "position": { "x": 190, "y": 19 }, "dynamicImageUrl": this.preloadedImages[21].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I`m Waiting", "show": false, "linkable": "", "call": true },
          { "id": 23, "reSize": "480", "position": { "x": 1421, "y": 400 }, "dynamicImageUrl": this.preloadedImages[22].src, "title": "Fine Japanese Automotive Deals! - Akkawi Automotive - Come See For Yourself!", "show": false },
          { "id": 24, "reSize": "480", "position": { "x": 980, "y": 449 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 25, "reSize": "480", "position": { "x": 990, "y": 459 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 26, "reSize": "480", "position": { "x": 1000, "y": 469 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 27, "reSize": "480", "position": { "x": 1010, "y": 479 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 28, "reSize": "480", "position": { "x": 1020, "y": 489 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 29, "reSize": "480", "position": { "x": 1030, "y": 499 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 30, "reSize": "480", "position": { "x": 1040, "y": 509 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 31, "reSize": "480", "position": { "x": 1050, "y": 519 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 32, "reSize": "480", "position": { "x": 1060, "y": 529 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 33, "reSize": "480", "position": { "x": 1070, "y": 539 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          // { "id": 34, "type": "simple", "position": { "x": 240, "y": 500 }, "title": "Naughty Bits", "show": false, "html": "<p style='font-size: 1.5rem;'>Hi</p>", button: false },
          // { "id": 35, "type": "simple", "position": { "x": 250, "y": 515 }, "title": "Naughty Bits", "show": false, "html": "<p style='font-size: 1.5rem;'>Will you play my not-so-erotic, erotic avdenture game?</p>", button: false },
          { "id": 36, "type": "simple", "position": { "x": 760, "y": 400 }, "title": "Naughty Bits", "show": false, "html": "<h4 style='margin-bottom: 0;font-size: 1.5rem;'>1-800-501-DOIT</h4><p style='margin-top: 5px;font-size: 1.1rem;'>Call. Play. Don't Die</p>", button: true }
        ];


        // IDs to ignore
        let idsToIgnore = []; // Ignoring objects with id 2

        // Iterate over each object in the array
        this.defaultPositions.forEach(obj => {
          // Check if the object's id is in the idsToIgnore array
          if (!idsToIgnore.includes(obj.id)) {
            // If not, access the 'data' array and modify the 'reSize' value
            obj.reSize = this.getTranslatedWidth(obj.reSize);
            obj.position.x = this.getTranslatedX(obj.position.x);
            obj.position.y = this.getTranslatedY(obj.position.y);
          }
        });

      }
      // else if(this.screenWidth >= 768 && this.screenWidth  <= 1024 && aspectRatio < 1){
      else if (this.screenWidth >= 768 && this.screenWidth <= 1024) {
        this.galleryPosition.x = 110;
        this.galleryPosition.y = 190;
        this.viewPosition.x = 140;
        this.viewPosition.y = 230;
        this.formPosition.x = 120;
        this.formPosition.y = 240;
        this.defaultPositions = [
          { "id": 1, "reSize": "720", "position": { "x": 27, "y": 28 }, "dynamicImageUrl": this.preloadedImages[0].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 2, "reSize": "720", "position": { "x": 484, "y": 409 }, "dynamicImageUrl": this.preloadedImages[1].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 3, "reSize": "720", "position": { "x": 459, "y": 47 }, "dynamicImageUrl": this.preloadedImages[2].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 4, "reSize": "720", "position": { "x": 76, "y": 9 }, "dynamicImageUrl": this.preloadedImages[3].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Your Horse Awaits", "show": false, "linkable": "", "call": true },
          { "id": 5, "reSize": "720", "position": { "x": 1164, "y": 0 }, "dynamicImageUrl": this.preloadedImages[4].src, "title": "Magic In Every Box! - Heminger`s Box - One Is All It Takes", "show": false, "linkable": "", "call": false },
          { "id": 6, "reSize": "720", "position": { "x": 862, "y": 328 }, "dynamicImageUrl": this.preloadedImages[5].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Dare To Try?", "show": false, "linkable": "", "call": true },
          { "id": 7, "reSize": "720", "position": { "x": 1114, "y": 58 }, "dynamicImageUrl": this.preloadedImages[6].src, "title": "CALL NOW! - Going Somewhere? - Thiermann Travel", "show": false, "linkable": "", "call": false },
          { "id": 8, "reSize": "720", "position": { "x": 192, "y": 375 }, "dynamicImageUrl": this.preloadedImages[7].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 9, "reSize": "720", "position": { "x": 1006, "y": 184 }, "dynamicImageUrl": this.preloadedImages[8].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 10, "reSize": "720", "position": { "x": 70, "y": 155 }, "dynamicImageUrl": this.preloadedImages[9].src, "title": "Close In 5 Minutes! - Marns For Men - Pants and Other Essentials!", "show": false, "linkable": "", "call": false },
          { "id": 11, "reSize": "720", "position": { "x": 1110, "y": 360 }, "dynamicImageUrl": this.preloadedImages[10].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 12, "reSize": "720", "position": { "x": 840, "y": 624 }, "dynamicImageUrl": this.preloadedImages[11].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 13, "reSize": "720", "position": { "x": 524, "y": 570 }, "dynamicImageUrl": this.preloadedImages[12].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 14, "reSize": "720", "position": { "x": 29, "y": 418 }, "dynamicImageUrl": this.preloadedImages[13].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I Want You", "show": false, "linkable": "", "call": true },
          { "id": 15, "reSize": "720", "position": { "x": 563, "y": 410 }, "dynamicImageUrl": this.preloadedImages[14].src, "title": "BUY NOW! - Vulcomp - All New Vulcomp MK5 - NEW!", "show": false, "linkable": "https://www.vulcomp.com/" },
          { "id": 16, "reSize": "204", "position": { "x": 1622, "y": 746 }, "dynamicImageUrl": this.preloadedImages[15].src, "title": "Just One Drop! - Hart`s Wonder Elixir - Dare To Try?", "show": false, "linkable": "", "call": false },
          { "id": 17, "reSize": "720", "position": { "x": 1020, "y": 604 }, "dynamicImageUrl": this.preloadedImages[16].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Explore Now!", "show": false, "linkable": "", "call": true },
          { "id": 18, "reSize": "720", "position": { "x": 1152, "y": 522 }, "dynamicImageUrl": this.preloadedImages[17].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Come Join Us", "show": false, "linkable": "", "call": true },
          { "id": 19, "reSize": "720", "position": { "x": 990, "y": 560 }, "dynamicImageUrl": this.preloadedImages[18].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 20, "reSize": "200", "position": { "x": 1512, "y": 550 }, "dynamicImageUrl": this.preloadedImages[19].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 21, "reSize": "720", "position": { "x": 63, "y": 750 }, "dynamicImageUrl": this.preloadedImages[20].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I`m On The Line", "show": false, "linkable": "", "call": true },
          { "id": 22, "reSize": "720", "position": { "x": 270, "y": 606 }, "dynamicImageUrl": this.preloadedImages[21].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I`m Waiting", "show": false, "linkable": "", "call": true },
          { "id": 23, "reSize": "720", "position": { "x": 1164, "y": 500 }, "dynamicImageUrl": this.preloadedImages[22].src, "title": "Fine Japanese Automotive Deals! - Akkawi Automotive - Come See For Yourself!", "show": false },
          { "id": 24, "reSize": "720", "position": { "x": 680, "y": 649 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 25, "reSize": "720", "position": { "x": 700, "y": 659 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 26, "reSize": "720", "position": { "x": 720, "y": 669 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 27, "reSize": "720", "position": { "x": 740, "y": 679 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 28, "reSize": "720", "position": { "x": 760, "y": 689 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 29, "reSize": "720", "position": { "x": 780, "y": 699 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 30, "reSize": "720", "position": { "x": 800, "y": 709 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 31, "reSize": "720", "position": { "x": 820, "y": 719 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 32, "reSize": "720", "position": { "x": 840, "y": 729 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 33, "reSize": "720", "position": { "x": 860, "y": 739 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          // { "id": 34, "type": "simple", "position": { "x": 480, "y": 320 }, "title": "Naughty Bits", "show": false, "html": "<p style='font-size: 1.5rem;'>Hi</p>", button: false },
          // { "id": 35, "type": "simple", "position": { "x": 490, "y": 335 }, "title": "Naughty Bits", "show": false, "html": "<p style='font-size: 1.5rem;'>Will you play my not-so-erotic, erotic avdenture game?</p>", button: false },
          { "id": 36, "type": "simple", "position": { "x": 500, "y": 350 }, "title": "Naughty Bits", "show": false, "html": "<h4 style='margin-bottom: 0;font-size: 1.5rem;'>1-800-501-DOIT</h4><p style='margin-top: 5px;font-size: 1.1rem;'>Call. Play. Don't Die</p>", button: true }
        ];


        // IDs to ignore
        let idsToIgnore = []; // Ignoring objects with id 2

        // Iterate over each object in the array
        this.defaultPositions.forEach(obj => {
          // Check if the object's id is in the idsToIgnore array
          if (!idsToIgnore.includes(obj.id)) {
            // If not, access the 'data' array and modify the 'reSize' value
            obj.reSize = this.getTranslatedWidth(obj.reSize);
            obj.position.x = this.getTranslatedX(obj.position.x);
            obj.position.y = this.getTranslatedY(obj.position.y);
          }
        });
      }
      else {
        this.formPosition.x = 0;
        this.formPosition.y = 120;
        this.sourceResolutionY = 924;
        this.galleryPosition.x = 0;
        this.galleryPosition.y = 150;
        this.viewPosition.x = 0;
        this.viewPosition.y = 70;
        this.defaultPositions = [
          { "id": 1, "reSize": "480", "position": { "x": 0, "y": 64 }, "dynamicImageUrl": this.preloadedImages[0].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 2, "reSize": "480", "position": { "x": 0, "y": 190 }, "dynamicImageUrl": this.preloadedImages[1].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 4, "reSize": "480", "position": { "x": 0, "y": 352 }, "dynamicImageUrl": this.preloadedImages[3].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Your Horse Awaits", "show": false, "linkable": "", "call": true },
          { "id": 5, "reSize": "480", "position": { "x": 0, "y": 44 }, "dynamicImageUrl": this.preloadedImages[4].src, "title": "Magic In Every Box! - Heminger`s Box - One Is All It Takes", "show": false, "linkable": "", "call": false },
          { "id": 6, "reSize": "480", "position": { "x": 0, "y": 142 }, "dynamicImageUrl": this.preloadedImages[5].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Dare To Try?", "show": false, "linkable": "", "call": true },
          { "id": 8, "reSize": "480", "position": { "x": 0, "y": 293 }, "dynamicImageUrl": this.preloadedImages[7].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 7, "reSize": "480", "position": { "x": 0, "y": 4 }, "dynamicImageUrl": this.preloadedImages[6].src, "title": "CALL NOW! - Going Somewhere? - Thiermann Travel", "show": false, "linkable": "", "call": false },
          { "id": 9, "reSize": "480", "position": { "x": 0, "y": 211 }, "dynamicImageUrl": this.preloadedImages[8].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 10, "reSize": "480", "position": { "x": 0, "y": 85 }, "dynamicImageUrl": this.preloadedImages[9].src, "title": "Close In 5 Minutes! - Marns For Men - Pants and Other Essentials!", "show": false, "linkable": "", "call": false },
          { "id": 11, "reSize": "480", "position": { "x": 0, "y": 389 }, "dynamicImageUrl": this.preloadedImages[10].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 12, "reSize": "480", "position": { "x": 0, "y": 228 }, "dynamicImageUrl": this.preloadedImages[11].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 13, "reSize": "480", "position": { "x": 0, "y": 348 }, "dynamicImageUrl": this.preloadedImages[12].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 14, "reSize": "480", "position": { "x": 0, "y": 178 }, "dynamicImageUrl": this.preloadedImages[13].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I Want You", "show": false, "linkable": "", "call": true },
          { "id": 17, "reSize": "480", "position": { "x": 0, "y": 495 }, "dynamicImageUrl": this.preloadedImages[16].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Explore Now!", "show": false, "linkable": "", "call": true },
          { "id": 16, "reSize": "100", "position": { "x": 40, "y": 285 }, "dynamicImageUrl": this.preloadedImages[15].src, "title": "Just One Drop! - Hart`s Wonder Elixir - Dare To Try?", "show": false, "linkable": "", "call": false },
          { "id": 19, "reSize": "480", "position": { "x": 0, "y": 441 }, "dynamicImageUrl": this.preloadedImages[18].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 18, "reSize": "480", "position": { "x": 0, "y": 24 }, "dynamicImageUrl": this.preloadedImages[17].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - Come Join Us", "show": false, "linkable": "", "call": true },
          { "id": 20, "reSize": "200", "position": { "x": 0, "y": 95 }, "dynamicImageUrl": this.preloadedImages[19].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          { "id": 21, "reSize": "480", "position": { "x": 0, "y": 455 }, "dynamicImageUrl": this.preloadedImages[20].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I`m On The Line", "show": false, "linkable": "", "call": true },
          { "id": 22, "reSize": "480", "position": { "x": 0, "y": 205 }, "dynamicImageUrl": this.preloadedImages[21].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - I`m Waiting", "show": false, "linkable": "", "call": true },
          { "id": 23, "reSize": "480", "position": { "x": 0, "y": 92 }, "dynamicImageUrl": this.preloadedImages[22].src, "title": "Fine Japanese Automotive Deals! - Akkawi Automotive - Come See For Yourself!", "show": false, "linkable": "" },
          { "id": 15, "reSize": "480", "position": { "x": 0, "y": 296 }, "dynamicImageUrl": this.preloadedImages[14].src, "title": "BUY NOW! - Vulcomp - All New Vulcomp MK5 - NEW!", "show": false, "linkable": "https://www.vulcomp.com/" },
          { "id": 24, "reSize": "480", "position": { "x": 0, "y": 16 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 25, "reSize": "480", "position": { "x": 0, "y": 31 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 26, "reSize": "480", "position": { "x": 0, "y": 46 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 27, "reSize": "480", "position": { "x": 0, "y": 61 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 28, "reSize": "480", "position": { "x": 0, "y": 76 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 29, "reSize": "480", "position": { "x": 0, "y": 91 }, "dynamicImageUrl": this.preloadedImages[23].src, "title": "CLICK NOW! - Adult Supply International - Erotic Supply", "show": false, "linkable": "https://www.adultsupplyinternational.com/" },
          { "id": 30, "reSize": "480", "position": { "x": 0, "y": 236 }, "dynamicImageUrl": this.preloadedImages[2].src, "title": "Call Now! 1-800-501-DOIT - Naughty Bits - A World Of Delights", "show": false, "linkable": "", "call": true },
          // { "id": 31, "type": "simple", "position": { "x": 15, "y": 335 }, "title": "Naughty Bits", "show": false, "html": "<p style='font-size: 1.3rem;'>Hi</p>", button: false },
          // { "id": 32, "type": "simple", "position": { "x": 30, "y": 350 }, "title": "Naughty Bits", "show": false, "html": "<p style='font-size: 1.3rem;'>Will you play my not-so-erotic, erotic avdenture game?</p>", button: false },
          { "id": 33, "type": "simple", "position": { "x": 30, "y": 375 }, "title": "Naughty Bits", "show": false, "html": "<h4 style='margin-bottom: 0;font-size: 1.4rem;'>1-800-501-DOIT</h4><p style='margin-top: 5px;font-size: 1.1rem;'>Call. Play. Don't Die</p>", button: true }
        ]

        // IDs to ignore
        let idsToIgnore = []; // Ignoring objects with id 2

        // Iterate over each object in the array
        this.defaultPositions.forEach(obj => {
          // Check if the object's id is in the idsToIgnore array
          if (!idsToIgnore.includes(obj.id)) {
            // If not, access the 'data' array and modify the 'reSize' value
            // obj.reSize = this.getTranslatedWidth(obj.reSize);
            // obj.position.x = this.getTranslatedX(obj.position.x);
            obj.position.y = this.getTranslatedY(obj.position.y);
          }
        });
      }
      this.isLoading = false;
    });
  },
  methods: {
    handleTouchStart(e) {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    },
    addPic(data) {
      // Create a copy of the data object to avoid modifying the original
      const newItem = { ...data };
      // Assign a unique ID based on the current length of galleryPics plus 1
      newItem.id = this.galleryPics.length + 1;
      // Push the modified item into galleryPics
      this.galleryPics.push(newItem);
    },
    setFocus(selectedPopup) {
      this.galleryPics.forEach(popup => {
        if (popup === selectedPopup) {
          // Toggle IsInactive only if it's currently false
          if (!popup.IsInactive) {
            popup.IsInactive = true;
            popup.zIndex = 999;
          }
        } else {
          // Set IsInactive to false for all other popups
          popup.IsInactive = false;
          popup.zIndex = 0;
        }
      });
    },
    closePopup(popupId) {
      this.galleryPics = this.galleryPics.filter(popup => popup.id !== popupId);
    },
    applyAudio() {
      this.audioValue = 1;
    },
    preloadSound() {
      this.audio = new Audio(); // Create a new Audio object
      this.audio.src = soundPop; // Set the source of the audio file
      this.audio.preload = 'auto'; // Set the preload property to 'auto' to preload the audio file
      this.audio.load(); // Load the audio file
    },
    playAudio() {
      if (this.audio && this.audioValue == 1) {
        this.audio.currentTime = 0; // Reset audio playback to the beginning
        this.audio.play(); // Play the audio
      }
    },
    openGallery() {
      if (!this.isGallery) {
        this.isGallery = true;
      }
    },
    preloadImages(callback) {
      // Check if preloaded images are stored in localStorage
      const storedImages = localStorage.getItem('preloadedImages');

      if (storedImages) {
        // If preloaded images are stored, parse them and use them
        const storedImageData = JSON.parse(storedImages);

        storedImageData.forEach((imageData, index) => {
          const img = new Image();
          img.onload = () => {
            this.loadedImages++;
            if (this.loadedImages === storedImageData.length && typeof callback === 'function') {
              callback();
            }
          };
          img.src = imageData;
          this.preloadedImages[index] = img; // Store the image at the correct index
        });

        // Update totalImages based on the number of stored images
        this.totalImages = this.preloadedImages.length;
      } else {
        // If preloaded images are not stored, preload and store them
        this.totalImages = this.images.length;
        let loadedCount = 0;

        this.images.forEach((imageSrc, index) => {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', imageSrc, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            if (xhr.status === 200) {
              const reader = new FileReader();
              reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                  loadedCount++;
                  this.loadedImages++;
                  if (loadedCount === this.totalImages) {
                    // All images are loaded
                    // Convert images to Base64 and store them in localStorage
                    const base64Images = this.preloadedImages.map(img => {
                      return img.src;
                    });
                    localStorage.setItem('preloadedImages', JSON.stringify(base64Images));

                    // Call the callback function if provided
                    if (typeof callback === 'function') {
                      callback();
                    }
                  }
                };
                img.src = reader.result;
                this.preloadedImages[index] = img; // Store the image at the correct index
              };
              reader.readAsDataURL(xhr.response);
            }
          };
          xhr.send();
        });
      }
    },
    // preloadImages(callback) {
    //   // Check if preloaded images are stored in localStorage
    //   const storedImages = localStorage.getItem('preloadedImages');

    //   if (storedImages) {
    //     // If preloaded images are stored, parse them and use them
    //     const storedImageSrcs = JSON.parse(storedImages);

    //     storedImageSrcs.forEach(imageSrc => {
    //       const img = new Image();
    //       img.onload = () => {
    //         this.loadedImages++;
    //         if (this.loadedImages === storedImageSrcs.length && typeof callback === 'function') {
    //           callback();
    //         }
    //       };
    //       img.src = imageSrc;
    //       this.preloadedImages.push(img);
    //     });

    //     // Update totalImages based on the number of stored images
    //     this.totalImages = this.preloadedImages.length;
    //   } else {
    //     // If preloaded images are not stored, preload and store them
    //     this.totalImages = this.images.length;

    //     this.images.forEach(imageSrc => {
    //       const img = new Image();
    //       img.onload = () => {
    //         this.loadedImages++;
    //         if (this.loadedImages === this.totalImages) {
    //           // All images are loaded
    //           // Convert images to Base64 and store them in localStorage
    //           const base64Images = this.preloadedImages.map(img => {
    //             const canvas = document.createElement('canvas');
    //             const context = canvas.getContext('2d');
    //             canvas.width = img.width;
    //             canvas.height = img.height;
    //             context.drawImage(img, 0, 0, img.width, img.height);
    //             return canvas.toDataURL();
    //           });
    //           localStorage.setItem('preloadedImages', JSON.stringify(base64Images));

    //           // Call the callback function if provided
    //           if (typeof callback === 'function') {
    //             callback();
    //           }
    //         }
    //       };
    //       img.src = imageSrc;
    //       this.preloadedImages.push(img);
    //     });
    //   }
    //   },
    emptyClick() {
      if (this.isStartMenu) {
        this.isStartMenu = false;
      }
    },
    toggleStartMenu() {
      this.isStartMenu = !this.isStartMenu;
    },
    toggleContactForm() {
      this.isContactForm = !this.isContactForm;
    },
    toggleGallery() {
      this.isGallery = !this.isGallery;
    },
    toggleIcon(iconId) {
      if (this.selectedIcon === iconId) {
        this.selectedIcon = null; // Deselect if already selected
      } else {
        this.selectedIcon = iconId; // Select if not already selected
      }
    },
    deselectIcon(event) {
      // Deselect icon only if the background is clicked (not an icon)
      if (!event.target.closest(".icon")) {
        this.selectedIcon = null;
      }
    },
    getTranslatedX(x) {
      return Math.round((x / this.sourceResolutionX) * this.checkResolutionX);
    },
    getTranslatedY(y) {
      return Math.round((y / this.sourceResolutionY) * this.checkResolutionY);
    },
    getTranslatedWidth(width) {
      let scalingFactor = this.checkResolutionX / this.sourceResolutionX;
      return Math.round(width * scalingFactor);
    },
    handleResize() {

      this.updateScreen();
    },
    updateScreen() {
      const widthScale = window.innerWidth / this.screenWidth
      const heightScale = window.innerHeight / this.screenHeight;


      this.defaultPositions.forEach(obj => {
        // Check if the object's id is in the idsToIgnore array
        // If not, access the 'data' array and modify the 'reSize' value
        //obj.reSize = this.getTranslatedWidth(obj.reSize);
        obj.position.x *= widthScale;
        obj.position.y *= heightScale;
        obj.reSize *= widthScale;
      });

      // Update original window dimensions
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }
  },
  computed: {
    checkResolutionX() {
      return this.screenWidth;
    },
    checkResolutionY() {
      return this.screenHeight;
    },
    progress() {
      return Math.ceil((this.loadedImages / this.totalImages) * 100);
    },
    checkPics() {
      if (this.galleryPics.length === 0) {
        return false;
      }
      return true;
    }
  },
};
</script>

<style scoped>
* {

  /* image-rendering: pixelated; */
}

/* Additional styles for the pop-up window */
/* .window {
  width: 300px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
} */


#app {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}

div[v-cloak] {
  display: none;
}

html {
  touch-action: none;
}

body {
  overflow-y: hidden;
  touch-action: pan-x pan-y;
}

.desktop {
  position: relative;
  width: 100%;
  /* height: calc(100vh - 40px); */
  height: 100vh;
  /* Adjust for start bar height */
  overflow: hidden;
  background-color: #008080;
  background-image: url("../src/assets/clouds.jpg");
  background-size: cover;
}

.container {
  display: flex;
  justify-content: center;
  /* Horizontally center the logo */
  align-items: center;
  /* Vertically center the logo */
  height: 100vh;
  /* Set the container height to full viewport height */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}

.loader-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #000;
  width: 360px;
  height: 200px;
  text-align: center;
}

.logo-loader {
  width: 65%;
  margin: 10px 0;
}

.loader-bars {
  display: flex;
  box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
  height: 30px;
  padding: 5px 4px 7px;
}

.loader-bar {
  width: 18px;
  height: 33px;
  background-color: #010081;
  margin-right: 3px;
}


.logo {
  max-width: 25%;
  /* Ensure the logo does not exceed its container */
  max-height: 100%;
  /* Ensure the logo does not exceed its container */
}

.icon-row {
  display: flex;
  margin: 10px;
  /* Add other row styles here */
}

.icon {
  width: 100px;
  /* Adjust width as needed */
  text-align: center;
  margin-right: 20px;
  /* Adjust spacing between icons as needed */
  cursor: inherit;
  padding: 5px;

  /* transition: background-color 0.3s; */
}

.icon img {
  width: 64px;
  /* Adjust icon size as needed */
  height: 64px;
  /* Adjust icon size as needed */
  margin-bottom: 5px;
}

.icon span {
  display: block;
  /* Add other text styles here */
}

.selected {
  background-color: #003366;
  /* Adjust to match Windows 98 highlight color */
  color: white;
  /* Text color when selected */
  mix-blend-mode: multiply;
}

@media screen and (max-width: 768px) {
  .logo {
    max-width: 80%;
    /* Ensure the logo does not exceed its container */
    max-height: 100%;
    /* Ensure the logo does not exceed its container */
  }
}
</style>

<style>
/* Global CSS styles */
body {
  margin: 0;
  overflow: hidden;
}
</style>
