<template>
  <div
    class="window" id="contact-form"
    :style="{ left: position.x + 'px', top: position.y + 'px', zIndex: checkZ }"
  >
    <div
      class="title-bar"
      :class="{ inactive: isActive }"
      @mousedown="startDrag"
      @touchstart="startDragTouch"
    >
      <div class="title-bar-text">Contact Form</div>

      <div class="title-bar-controls">
        <button aria-label="Minimize" @click="minimize"></button>
        <button disabled aria-label="Maximize"></button>
        <button aria-label="Close" @click="close"></button>
      </div>
    </div>
    <div v-if="!isSubmitted && !isSubmitting" class="window-body">
      <div class="field-row-stacked">
        <label for="text18">Name*</label>
        <input id="text18" type="text" v-model="userData.userName" placeholder="Your name" :class="{ 'incomplete': shouldShowError && userData.userName === '' }" />
      </div>
      <div class="field-row-stacked">
        <label for="text19">Email*</label>
        <input id="text19" type="text" v-model="userData.userEmail" @input="validateEmail" placeholder="Your email" :class="{ 'incomplete': shouldShowError && userData.userEmail === '' }" />
        <label v-if="!isValidEmail && userData.userEmail !== ''" style="color: red;">Oops! look your email is wrong.</label>
      </div>
      <div class="field-row-stacked">
        <label for="text20">Note*</label>
        <textarea id="text20" rows="8" v-model="userData.userNote" placeholder="Your Note" :class="{ 'incomplete': shouldShowError && userData.userNote === '' }"></textarea>
      </div>
      <div class="center">
        <button @click="submitForm" class="submit">Submit</button>
      </div> 
      <div class="center">
        <p v-if="incompleteFields.length > 0" style="color: red;">Hey! you cannot send with empty field like this.</p>
      </div>
    </div>
    <div v-else-if="isSubmitting && !isSubmitted" class="window-body">

    <div class="center" style="height: 270px;">
      <p v-if="!isError" style="font-size: 1.4rem;">Sending message...</p>
      <div v-else-if="isError"> 
        <p style="font-size: 1.4rem;">Ugh! there is an error, maybe try again later.</p>
        <p>Error message: {{ sendStatus }}</p>
      </div>
    </div> 

</div>
    <div v-else-if="!isSubmitting && isSubmitted" class="window-body">

      <div class="center" style="height: 270px;">
        <p style="font-size: 1.4rem;">Submitted</p>
      </div> 

    </div>
  </div>
</template>

<script>
  export default {
    props: ['initialPosition', 'isInactive', 'zIndex'],
    data() {
      return {
        //zIndex: 0,
        position: this.initialPosition,
        isDragging: false,
        startX: 0,
        startY: 0,
        isMinimized: false,
        userData: {
          userName: '',
          userEmail: '',
          userNote: ''
        },
        isValidEmail: true,
        shouldShowError: false,
        incompleteFields: [],
        isSubmitted: false,
        isSubmitting: false,
        isError: '',
        sendStatus: ''
        //isInactive: this.changeInactive
        //localIsInactive: this.isInactive 
      };
    },
    methods: {
      validateEmail() {
          // Regular expression for email validation
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

          if (this.userData.userEmail === '') {
            this.isValidEmail = true; // Email is valid if it's empty
          } else if (!emailRegex.test(this.userData.userEmail)) {
            this.isValidEmail = false; // Email is invalid if it doesn't match the regex
          } else {
            this.isValidEmail = true; // Otherwise, email is valid
          }
        },
      close(){
        this.$emit('close');
      },
      submitForm() {
          // Check for incomplete fields
          this.incompleteFields = [];
          this.shouldShowError = true;
          if (this.userData.userEmail === '' || !this.isValidEmail) {
            this.incompleteFields.push('Email');
          }
          if (this.userData.userName === '') {
            this.incompleteFields.push('Name');
          }
          if (this.userData.userNote === '') {
            this.incompleteFields.push('Note');
          }

          // If there are incomplete fields, prevent form submission
          if (this.incompleteFields.length > 0) {
            return;
          }

          // If all fields are filled, proceed with form submission
          //alert('Form submitted successfully!');
          //this.shouldShowError = false;
          this.isSubmitting = true;
          this.sendEmail();
        },
        sendEmail() {
          let url;
                if (process.env.NODE_ENV === 'production') {
                    // Use the production server URL
                    url = 'php/mail.php';
                } else {
                    // Use the test server URL
                    url = 'http://localhost/mail.php';
                }
            // Send form data to server using fetch API
            // if using laragon, replace http://localhost/mail.php
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(this.userData),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.text();
                }
                this.isError = true;
                this.sendStatus = 'Network response was not ok :(';
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                this.sendStatus = data;
                // Reset form after successful submission
                this.userData.userName = '';
                this.userData.userEmail = '';
                this.userData.userNote = '';
                this.isSubmitting = false;
                this.isSubmitted = true;
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                this.isError = true;
                this.sendStatus = 'Error sending email. Please try again later.';
            });
        },
      minimize() {
        if (this.isMinimized) {
      return;
    }

    this.isMinimized = true;
      // Get the original title bar element
      const originalTitleBar = this.$el.querySelector('.title-bar');
      // Clone the title bar
      const clonedTitleBar = originalTitleBar.cloneNode(true);
      // Append cloned title bar to the document body
      document.body.appendChild(clonedTitleBar);

      // Get the bounding box of the original title bar
      const originalTitleBarRect = originalTitleBar.getBoundingClientRect();
      // Calculate the taskbar position (adjust as needed)
      const taskbarPosition = { x: 50, y: window.innerHeight - 50 };

      // Set the cloned title bar position to match the original title bar
      clonedTitleBar.style.position = 'fixed';
      clonedTitleBar.style.left = originalTitleBarRect.left + 'px';
      clonedTitleBar.style.top = originalTitleBarRect.top + 'px';
      clonedTitleBar.style.width = originalTitleBarRect.width + 'px';
      clonedTitleBar.style.transition = 'all 0.5s ease-in-out';
      clonedTitleBar.style.zIndex = 999;

      // Move the cloned title bar to the taskbar position with animation
      setTimeout(() => {
        //this.isMinimized = false;
        clonedTitleBar.style.left = taskbarPosition.x + 'px';
        clonedTitleBar.style.top = taskbarPosition.y + 'px';
      }, 0);

      // Simulate window disappearing (you can add your logic here)
      setTimeout(() => {
        // Remove the cloned title bar
        document.body.removeChild(clonedTitleBar);
        // Emit an event to close the window
        this.$emit('close');
        this.isMinimized = false;
      }, 500); // Adjust delay as needed
    },
      startDrag(event) {
        this.isDragging = true;
        this.startX = event.clientX - this.position.x;
        this.startY = event.clientY - this.position.y;
        //this.zIndex = this.zIndex+10;
        //this.isInactive = !this.isInactive;
        this.$emit('changeFocus', this.popupId);
        // this.$parent.resetZIndices(this.popupId);
        document.addEventListener('mousemove', this.handleDrag);
        document.addEventListener('mouseup', this.endDrag);
        document.body.style.overflowX = 'hidden'; // Prevent horizontal scrolling
      },
      startDragTouch(event) {
      this.isDragging = true;
      const touch = event.touches[0];
      this.startX = touch.clientX - this.position.x;
      this.startY = touch.clientY - this.position.y;
      //this.zIndex = this.zIndex + 10;
      //this.isInactive = !this.isInactive;
      this.$emit('changeFocus', this.popupId);
      // this.$parent.resetZIndices(this.popupId);
      document.addEventListener('touchmove', this.handleDragTouch);
      document.addEventListener('touchend', this.endDragTouch);
      document.body.style.overflowX = 'hidden'; // Prevent horizontal scrolling
    },
      handleDrag(event) {
      if (this.isDragging) {
        let newX = event.clientX - this.startX;
        let newY = event.clientY - this.startY;
        let maxX = window.innerWidth - this.$el.offsetWidth; // Limit to visible viewport width
        let maxY = window.innerHeight - this.$el.offsetHeight + 200; // Limit to visible viewport height
        newX = Math.min(Math.max(newX, 0), maxX); // Ensure within horizontal limits
        newY = Math.min(Math.max(newY, 0), maxY); // Ensure within vertical limits
        this.position.x = newX;
        this.position.y = newY;
      }
    },
    handleDragTouch(event) {
      if (this.isDragging) {
        //event.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
        const touch = event.touches[0];
        let newX = touch.clientX - this.startX;
        let newY = touch.clientY - this.startY;
        let maxX = window.innerWidth - this.$el.offsetWidth; // Limit to visible viewport width
        let maxY = window.innerHeight - this.$el.offsetHeight + 200; // Limit to visible viewport height
        newX = Math.min(Math.max(newX, 0), maxX); // Ensure within horizontal limits
        newY = Math.min(Math.max(newY, 0), maxY); // Ensure within vertical limits
        this.position.x = newX;
        this.position.y = newY;
      }
    },
      endDrag() {
        this.isDragging = false;
        //this.zIndex = 1;
        document.removeEventListener('mousemove', this.handleDrag);
        document.removeEventListener('mouseup', this.endDrag);
        document.body.style.overflowX = ''; // Re-enable horizontal scrolling
      },
      endDragTouch() {
      this.isDragging = false;
      document.removeEventListener('touchmove', this.handleDragTouch);
      document.removeEventListener('touchend', this.endDragTouch);
      document.body.style.overflowX = ''; // Re-enable horizontal scrolling
    },
      // closePopup() {
      //   this.$emit('close');
      // },
    //   createPage(url) {
    // // Open a new window or tab with the specified URL
    // window.open(url, '_blank');
    // },
    // makeCall() {
    //   // Replace phoneNumber with the actual phone number
    //   //alert("calling");
    //   const phoneNumber = "1-800-501-3648";
    //   window.location.href = `tel:${phoneNumber}`;
    // }
    },
    computed: {
    isActive() {
      return !this.isInactive; // Computed property to determine if the component is active or not
    },
    checkZ() {
      return this.zIndex; // Computed property to determine if the component is active or not
    }
  }
  };
</script>

<style scoped>
/* Pop-up window styles */

.incomplete {
      outline: 2px solid red;
    }

.window {
  position: absolute;
  /* max-width: 600px; */
  min-width: 150px;
  height: 350px;
  width: 550px;
  /* min-height: 200px; */
  /* background-color: #fff; */
  border: 1px solid #000;
  border: 0;
}

textarea{
  max-width: 100%;
  min-width: 75%;
  max-height: 140px;
  min-height: 70px;
}

.window-header {
  cursor: move;
  padding: 5px;
  /* background-color: #ccc; */
}

.window-body{
  margin: 1px 0 0 0;
    padding: 10px;
}

.field-row-stacked{
  width: 100%;
}

.window-content {
  padding: 10px;
}

.title-bar-text{
  white-space: nowrap;
  margin-right: 2px;
  display: flex;
  align-items: center;
  flex: 1; /* Allow the text to grow and shrink as needed */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis for overflow */
  font-size: 12px;
}

.center{
  display: flex;
  justify-content: center;
    align-items: center;
}

.submit{
  margin-top: 30px;
  width: 30%;
  height: 35px;
}

.title-bar{
  border-bottom: 1px solid lightgray;
}

.title-bar-controls{
  height: 16px;
}

.title-bar-controls button[aria-label=Maximize]:disabled {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width=%279%27 height=%279%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M9 0H0v9h9V0zM8 2H1v6h7V2z%27 fill=%27grey%27/%3E%3C/svg%3E")
}

.title-bar-controls button[aria-label=Maximize] {
    background-position: top 3px left 3px;
    background-repeat: no-repeat;
}

.title-bar.inactive > .title-bar-text {
    color: lightgrey;
}

.title-bar-controls button[aria-label=Close] {
    background-position: top 4px left 4px;
    background-repeat: no-repeat;
}


.popup-image {
  max-width: 100%; /* Limit maximum width to prevent oversizing */
  max-height: 100%; /* Limit maximum height to prevent oversizing */
}

.pointer{
  cursor: pointer;
  pointer-events: all !important;
}

.icon {
  width: 16px; /* Adjust icon size as needed */
  height: 16px;
  margin-right: 5px; /* Add some spacing between the icon and title text */
}

.cloned-title-bar {
  background-color: #ccc; /* Adjust as needed */
  border-bottom: 1px solid #000; /* Adjust as needed */
  z-index: 9999; /* Adjust z-index to ensure it's above other elements */
}

@media screen and (max-width: 768px) {
  .window {
    left: 0;
    /* transform: translateX(-50%); */
    max-width: 98%; /* Ensure the popup takes up the full width */
  }
}

</style>