<template>
    <div :class="{ 'window': true, 'adjust-center': type === 'simple' }" :id="'image-' + imageId"
        :style="{ left: position.x + 'px', top: position.y + 'px', zIndex: checkZ }">
        <div class="title-bar" :class="{ inactive: isActive }" @mousedown="startDrag" @touchstart="startDragTouch">
            <div class="title-bar-text" :style="{ maxWidth: getTitleWidth + 'px' }">
                <img src="../assets/icons/jpeg_icon.png" alt="Icon" class="icon">
                {{ truncatedText }}
            </div>

            <div class="title-bar-controls">
                <button aria-label="Minimize" @click="minimize"></button>
                <button disabled aria-label="Maximize"></button>
                <button aria-label="Close" @click="closePopup"></button>
            </div>
        </div>

        <div class="window-body">
            <!-- <p>This is an annoying pop-up window!</p> -->
            <img class="popup-image" draggable="false" width="420" :src="imageUrl" :alt="title" ref="image">
        </div>

    </div>

</template>
<!-- cambia initialPosition para vincular, no copiar -->
<script>

export default {
    props: ['initialPosition', 'imageId', 'imageUrl', 'title', 'isInactive', 'zIndex', 'reSize', 'linkable', 'call', 'type', 'button', 'text', 'html'],
    data() {
        return {
            //zIndex: 0,
            position: this.initialPosition,
            isDragging: false,
            startX: 0,
            startY: 0,
            isMinimized: false,
            maxTextWidth: null,
            truncatedText: this.title,
            //isInactive: this.changeInactive
            //localIsInactive: this.isInactive 
        };
    },
    methods: {
        minimize() {
            if (this.isMinimized) {
                return;
            }

            this.isMinimized = true;
            // Get the original title bar element
            //BUG: WRONG PICKING ON HALF INSTANCE, PICK THE EXACT ID
            const originalTitleBar = this.$el.querySelector('.title-bar');
            // Clone the title bar
            const clonedTitleBar = originalTitleBar.cloneNode(true);
            // Append cloned title bar to the document body
            document.body.appendChild(clonedTitleBar);

            // Get the bounding box of the original title bar
            const originalTitleBarRect = originalTitleBar.getBoundingClientRect();
            // Calculate the taskbar position (adjust as needed)
            const taskbarPosition = { x: 50, y: window.innerHeight - 50 };

            // Set the cloned title bar position to match the original title bar
            clonedTitleBar.style.position = 'fixed';
            clonedTitleBar.style.left = originalTitleBarRect.left + 'px';
            clonedTitleBar.style.top = originalTitleBarRect.top + 'px';
            clonedTitleBar.style.width = originalTitleBarRect.width + 'px';
            clonedTitleBar.style.transition = 'all 0.5s ease-in-out';
            clonedTitleBar.style.zIndex = 1000;

            // Move the cloned title bar to the taskbar position with animation
            setTimeout(() => {
                //this.isMinimized = false;
                clonedTitleBar.style.left = taskbarPosition.x + 'px';
                clonedTitleBar.style.top = taskbarPosition.y + 'px';
            }, 0);

            // Simulate window disappearing (you can add your logic here)
            setTimeout(() => {
                // Remove the cloned title bar
                document.body.removeChild(clonedTitleBar);
                // Emit an event to close the window
                this.$emit('close', this.imageId);
            }, 500); // Adjust delay as needed
        },
        startDrag(event) {
            this.isDragging = true;
            this.startX = event.clientX - this.position.x;
            this.startY = event.clientY - this.position.y;
            //this.zIndex = this.zIndex+10;
            //this.isInactive = !this.isInactive;
            this.$emit('changeFocus', this.imageId);
            // this.$parent.resetZIndices(this.ImageId);
            document.addEventListener('mousemove', this.handleDrag);
            document.addEventListener('mouseup', this.endDrag);
            document.body.style.overflowX = 'hidden'; // Prevent horizontal scrolling
        },
        startDragTouch(event) {
            this.isDragging = true;
            const touch = event.touches[0];
            this.startX = touch.clientX - this.position.x;
            this.startY = touch.clientY - this.position.y;
            //this.zIndex = this.zIndex + 10;
            //this.isInactive = !this.isInactive;
            this.$emit('changeFocus', this.imageId);
            // this.$parent.resetZIndices(this.ImageId);
            document.addEventListener('touchmove', this.handleDragTouch);
            document.addEventListener('touchend', this.endDragTouch);
            document.body.style.overflowX = 'hidden'; // Prevent horizontal scrolling
        },
        handleDrag(event) {
            if (this.isDragging) {
                let newX = event.clientX - this.startX;
                let newY = event.clientY - this.startY;
                let maxX = window.innerWidth - this.$el.offsetWidth; // Limit to visible viewport width
                let maxY = window.innerHeight - this.$el.offsetHeight + 200; // Limit to visible viewport height
                newX = Math.min(Math.max(newX, 0), maxX); // Ensure within horizontal limits
                newY = Math.min(Math.max(newY, 0), maxY); // Ensure within vertical limits
                this.position.x = newX;
                this.position.y = newY;
            }
        },
        handleDragTouch(event) {
            if (this.isDragging) {
                //event.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
                const touch = event.touches[0];
                let newX = touch.clientX - this.startX;
                let newY = touch.clientY - this.startY;
                let maxX = window.innerWidth - this.$el.offsetWidth; // Limit to visible viewport width
                let maxY = window.innerHeight - this.$el.offsetHeight + 200; // Limit to visible viewport height
                newX = Math.min(Math.max(newX, 0), maxX); // Ensure within horizontal limits
                newY = Math.min(Math.max(newY, 0), maxY); // Ensure within vertical limits
                this.position.x = newX;
                this.position.y = newY;
            }
        },
        endDrag() {
            this.isDragging = false;
            //this.zIndex = 1;
            document.removeEventListener('mousemove', this.handleDrag);
            document.removeEventListener('mouseup', this.endDrag);
            document.body.style.overflowX = ''; // Re-enable horizontal scrolling
        },
        endDragTouch() {
            this.isDragging = false;
            document.removeEventListener('touchmove', this.handleDragTouch);
            document.removeEventListener('touchend', this.endDragTouch);
            document.body.style.overflowX = ''; // Re-enable horizontal scrolling
        },
        closePopup() {
            this.$emit('close', this.ImageId);
        },
    },
    computed: {
        getTitleWidth() {
            return this.reSize - 65; // Adjust for icon width
        },
        truncatedTitle() {
            // if (!this.maxTextWidth) return this.title;
            // const availableWidth = this.maxTextWidth;
            // const textWidth = this.$refs.text.scrollWidth;
            // if (textWidth > availableWidth) {
            //   return this.title.substring(0, Math.floor(availableWidth / 8)) + '...';
            // } else {
            return this.title;
            // }
        },
        isActive() {
            return !this.isInactive; // Computed property to determine if the component is active or not
        },
        checkZ() {
            return this.zIndex; // Computed property to determine if the component is active or not
        }
    }
};
</script>

<style scoped>
/* Pop-up window styles */
.window {
    position: absolute;
    /* max-width: 600px; */
    min-width: 55px;
    /* min-height: 200px; */
    /* background-color: #fff; */
    border: 1px solid #000;
    user-select: none;
    border: 0;
}

.window-header {
    cursor: move;
    padding: 5px;
    /* background-color: #ccc; */
}

.no-m-bot {
    margin-bottom: 0;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
}

.m-top-60 {
    margin-top: 15px;
}

.simple {
    pointer-events: all !important;
    box-shadow: none !important;
    border-top: none !important;
    height: 250px !important;
    width: 400px !important;
    font-family: 'Times New Roman', Times, serif;
    /* font-size: 1.5rem; */
}

.h200 {
    height: 200px;
}



.pointer-btn {
    cursor: pointer !important;
    width: 130px;
    height: 40px;
    font-size: 18px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.window-content {
    padding: 10px;
}

.title-bar-text {
    white-space: nowrap;
    margin-right: 2px;
    display: flex;
    align-items: center;
    flex: 1;
    /* Allow the text to grow and shrink as needed */
    overflow: hidden;
    /* Hide overflowing text */
    text-overflow: ellipsis;
    /* Display ellipsis for overflow */
    font-size: 12px;
}


.title-bar {
    border-bottom: 1px solid lightgray;
}

.title-bar-controls {
    height: 16px;
}

.title-bar-controls button[aria-label=Maximize]:disabled {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width=%279%27 height=%279%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M9 0H0v9h9V0zM8 2H1v6h7V2z%27 fill=%27grey%27/%3E%3C/svg%3E")
}

.title-bar-controls button[aria-label=Maximize] {
    background-position: top 3px left 3px;
    background-repeat: no-repeat;
}

.title-bar.inactive>.title-bar-text {
    color: lightgrey;
}

.title-bar-controls button[aria-label=Close] {
    background-position: top 4px left 4px;
    background-repeat: no-repeat;
}

.window-body {
    margin: 1px 0 0 0;
    pointer-events: none;
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
    padding: 0 1px 0px 1px;
    width: fit-content;
    border-top: 1px solid #000;
}

.popup-image {
    max-width: 100%;
    /* Limit maximum width to prevent oversizing */
    max-height: 100%;
    /* Limit maximum height to prevent oversizing */
}

.pointer {
    cursor: pointer;
    pointer-events: all !important;
}

.icon {
    width: 16px;
    /* Adjust icon size as needed */
    height: 16px;
    margin-right: 5px;
    /* Add some spacing between the icon and title text */
}

.cloned-title-bar {
    background-color: #ccc;
    /* Adjust as needed */
    border-bottom: 1px solid #000;
    /* Adjust as needed */
    z-index: 9999;
    /* Adjust z-index to ensure it's above other elements */
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns with equal width */
    grid-gap: 10px;
    /* Gap between grid items */
    padding: 20px;
    /* Padding for the grid container */
}

.grid-item {
    background-color: #f2f2f2;
    /* Background color of grid items */
    padding: 20px;
    /* Padding for grid items */
    text-align: center;
    /* Center-align text */
    border-radius: 5px;
    /* Rounded corners for grid items */
}


@media screen and (max-width: 768px) {
    .simple {
        height: 210px !important;
        width: 320px !important;
        font-size: 1.1rem !important;
    }

    .adjust-center {
        transform: translateX(-50%);
        left: 50% !important;
    }

    .no-m-bot {
        margin-top: 20px;
    }

    .window {
        width: 99%;
    }

}
</style>
