<template>
  <div>

    <PopUpWindow v-for="popup in setPositions" :key="popup.id" :initialPosition="popup.position" :popupId="popup.id"
      :imageUrl="popup.dynamicImageUrl" :title="popup.title" :isInactive="popup.IsInactive" :zIndex="popup.zIndex"
      :reSize="popup.reSize" :show="popup.show" :linkable="popup.linkable" :call="popup.call" :type="popup.type"
      :text="popup.text" :button="popup.button" :html="popup.html" @close="closePopup(popup.id)"
      @changeFocus="setFocus(popup)">
    </PopUpWindow>



  </div>
</template>



<script>


import PopUpWindow from './PopUpWindow.vue';


export default {
  components: {
    PopUpWindow
  },
  props: {
    enableAudio: Number,
    dataset: Array,
    playAudio: Function
  },
  data() {
    return {
      setPositions: this.dataset,
      displayedPopups: [],
      popupIndex: 0,
      intervalId: null,
      time: 100,
      focusedItem: null,
      renderedPositions: [],
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  },
  mounted() {
    //this.preloadImages(); // Preload images before calling delayRender
    this.delayRender();
  },

  methods: {
    delayRender() {

      let delays = [];
      if (this.screenWidth > 1024) {
        delays = [400, 400, 300, 300, 300, 300, 200, 200, 100, 100,
          100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
          100, 100, 100, 100, 50, 50, 50, 50, 50, 50, 50, 50,
          5000,
          // 2000, 2000
        ]
      }
      else if (this.screenWidth >= 768 && this.screenWidth <= 1024) {
        delays = [400, 400, 300, 300, 300, 300, 200, 200, 100, 100,
          100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
          100, 100, 100, 100, 50, 50, 50, 50, 50, 50, 50, 50,
          5000,
          // 2000, 2000
        ]
      }
      else {
        delays = [400, 400, 300, 300, 300, 300, 200, 200, 100, 100,
          100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
          100, 100, 100, 100, 50, 50, 50, 50,
          5000,
          // 2000, 2000
        ]
      }

      let cumulativeDelay = 1000; // Initial cumulative delay

      this.setPositions.forEach((popup, index) => {
        setTimeout(() => {
          // Check if the popup is already rendered before setting show to true
          if (!popup.show) {
            popup.show = true;
            this.setFocus(popup);
            this.playAudio();
          }

          // Update the computed property after the delay
          this.renderedPositions = this.dataset.filter(p => p.show);
        }, cumulativeDelay);

        cumulativeDelay += delays[index] || 0; // Use the delay corresponding to the current index or 0 if not defined
      });
    },
    //   callLoader(){
    //     this.preloadImages(() => {
    //   //console.log('Todas las imágenes han sido cargadas');
    //     //this.$emit('preloadImagesDone', true)
    //     this.generatesetPositions();
    //     this.startPopupInterval();
    //   // Aquí puedes realizar otras acciones después de la carga de imágenes
    // });
    //   },
    startPopupInterval() {
      this.intervalId = setInterval(this.showNextPopup, this.time); // Start the interval with initial time
    },
    // preloadImages() {
    //     this.setPositions.forEach(popup => {
    //       this.preloadImage(popup.imageUrl);
    //     });
    //   },
    //   preloadImage(imageUrl) {
    //     const img = new Image();
    //     img.src = imageUrl;
    //   },
    handleResize() {
      //this.generatesetPositions();
      // this.updateDisplayedPopupsPositions(); // Update positions after resizing
      this.displayedPopups.forEach(popup => {
        const defaultPosition = this.setPositions.find(pos => pos.id === popup.id).position;
        popup.position = { ...defaultPosition };
      });
    },
    generatesetPositions() {
      // Define your pop-up window positions in JSON format


      // const mobilePositions = [

      // ];

      // Assign positions based on device width
      // this.setPositions = window.innerWidth >= 768 ? this.setPositions : mobilePositions;

      // Push each position from popupPositions into setPositions
      //   popupPositions.forEach(position => {
      //     this.setPositions.push(position);
      //   });
      // for (let i = 0; i < 20; i++) {
      //   this.setPositions.push({
      //     id: i,
      //     position: {
      //       x: Math.random() * (window.innerWidth - 300),
      //       y: Math.random() * (window.innerHeight - 200)
      //     }
      //   });
      // }
    },
    updateDisplayedPopupsPositions() {
      this.displayedPopups.forEach((popup, index) => {
        if (this.setPositions[index]) {
          popup.position = { ...this.setPositions[index].position };
        }
      });
    },
    showNextPopup() {
      if (this.audio && this.enableAudio == 1) {
        this.audio.currentTime = 0; // Reset audio playback to the beginning
        this.audio.play(); // Play the audio
      }

      if (this.popupIndex < this.setPositions.length) {
        this.displayedPopups.push(this.setPositions[this.popupIndex]);
        this.popupIndex++;

        if (this.displayedPopups.length > 23) {
          clearInterval(this.intervalId); // Clear the interval
          this.time = 50; // Change the interval time to 100ms
          this.startPopupInterval(); // Restart the interval with new time
        }
      } else {
        clearInterval(this.intervalId); // Stop the interval when all popups are shown
        this.time = 0;
      }
    },
    setFocus(selectedPopup) {
      this.setPositions.forEach(popup => {
        if (popup === selectedPopup) {
          // Toggle IsInactive only if it's currently false
          if (!popup.IsInactive) {
            popup.IsInactive = true;
            popup.zIndex = 999;
          }
        } else {
          // Set IsInactive to false for all other popups
          popup.IsInactive = false;
          popup.zIndex = 0;
        }
      });
    },
    closePopup(popupId) {
      this.setPositions = this.setPositions.filter(popup => popup.id !== popupId);
    },
  },
  computed: {
    filteredPopups() {
      return this.setPositions;
    }
  },

};
</script>


<style scoped>
@media screen and (max-width: 1024px) {
  .window {
    /* left: 0; */
    /* transform: translateX(-50%); */
    /* max-width: 98.8%; */
  }
}


@media screen and (max-width: 768px) {
  .window {
    left: 0;
    /* transform: translateX(-50%); */
    max-width: 98%;
    /* Ensure the popup takes up the full width */
  }
}
</style>