<template>
    <div>
    <div id="taskbar" class="start-bar">
    <div class="group-task">
      <!-- <button class="start-button" @click="toggleStartMenu">Start</button> -->
      <button :class="{ active: startMenu, 'start-button': true }" @click="toggleStartMenu"><img src="../assets/icons/V.png" alt="Icon" class="icon-v">Start</button>
      <div class="taskbar-divider"></div> <!-- Taskbar divider -->
    </div>
      <div class="start-menu window" v-if="startMenu">
        <div class="start-menu-body" v-if="isFontLoaded">
          <div class="start-menu-titlebar">

            <img :src="imageDone2" width="19px" class="start-menu-titlebar-img" alt="">
          </div>
        <div class="start-menu-items">
          <div class="start-menu-item home">
            Welcome Home Charlie! <br>
            Vulcomp Loves You
          </div>
          <div class="start-menu-item v-logo">
           <img :src="imageDone1" alt="" width="170px">
          </div>
          <div class="divider"></div>
          <div class="start-menu-item menu-link" @click="createPage('https://www.vulcomp.com/')">
           <img src="../assets/icons/rover.png" alt="Icon" class="icon">Vulcomp Website
          </div>
          <div class="start-menu-item menu-link" @click="createPage('https://www.adultsupplyinternational.com/')">
           <img src="../assets/icons/rover.png" alt="Icon" class="icon">ASI Website
          </div>
          <div class="start-menu-item menu-link" @click="openContactForm">
           <img src="../assets/icons/outlook_express-1.png" alt="Icon" class="icon">Contact
          </div>
          <div class="start-menu-item menu-link" @click="makeCall()">
           <img src="../assets/icons/tel.png" alt="Icon" class="icon">Call the hotline
          </div>
        </div>
        </div>
      </div>
          <!-- Current time -->
          <div class="group-task">
            <div class="taskbar-divider"></div> <!-- Taskbar divider -->
    <div class="current-time">
      <span style="margin-right: 10px;"><img class="task-icon" src="../assets/icons/time_and_date.png" alt="" /><img class="task-icon" src="../assets/icons/sound.png" alt=""></span>
      <span>{{ currentTime }}</span>
    </div>
    
  </div>
    </div>
    </div>
</template>

<script>

import image1 from '../assets/v.png';
import image2 from '../assets/firebird.png';

import font1 from '../assets/fonts/europeanteletext-webfont.woff'
import font2 from '../assets/fonts/europeanteletext-webfont.woff2'


export default {
  props: ['startMenu'],
  data() {
    return {
      currentTime: '',
      areImagesLoaded: false,
      isFontLoaded: false,
      imageDone1: '',
      imageDone2: ''
    };
  },
  mounted() {
    // Update current time every second
    this.updateCurrentTime();
    setInterval(this.updateCurrentTime, 1000);
    this.preloadFont();
    this.preloadImages();



  },
  methods: {
    preloadImages() {
      const imageUrl1 = image1; // Replace with the URL of your first image
      const imageUrl2 = image2; // Replace with the URL of your second image

      let loadedCount = 0;

      const checkAllImagesLoaded = () => {
        loadedCount++;
        if (loadedCount === 2) {
          this.areImagesLoaded = true;
        }
      };

      const img1 = new Image();
      img1.onload = () => {
        this.imageDone1 = img1.src; // Assign the URL to imageUrl1
        checkAllImagesLoaded();
      };
      img1.src = imageUrl1;

      const img2 = new Image();
      img2.onload = () => {
        this.imageDone2 = img2.src; // Assign the URL to imageUrl2
        checkAllImagesLoaded();
      };
      img2.src = imageUrl2;
    },
    toggleStartMenu() {
      this.$emit('start-button-clicked');
    },
    openContactForm(){
      this.$emit('start-button-clicked');
      this.$emit('contact-button-clicked');
    },
    // closeStartMenu() {
    //   this.startMenu = false;
    // },
    updateCurrentTime() {
      const now = new Date();
      let hours = now.getHours();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = (hours % 12) || 12; // Convert hours to 12-hour format
      const minutes = now.getMinutes().toString().padStart(2, '0');
      //const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes} ${ampm}`;
    },
    createPage(url) {
    // Open a new window or tab with the specified URL
    window.open(url, '_blank');  
    },
    makeCall() {
      // Replace phoneNumber with the actual phone number
      const phoneNumber = "1-800-501-3648";
      window.location.href = `tel:${phoneNumber}`;
    },
    preloadFont() {
      const fontLinks = [
        { rel: 'preload', href: font2, as: 'font', type: 'font/woff2', crossorigin: 'anonymous' },
        { rel: 'preload', href: font1, as: 'font', type: 'font/woff', crossorigin: 'anonymous' }
      ];

      fontLinks.forEach(link => {
        const preloadLink = document.createElement('link');
        preloadLink.rel = link.rel;
        preloadLink.href = link.href;
        preloadLink.as = link.as;
        preloadLink.type = link.type;
        preloadLink.crossOrigin = link.crossorigin;

        preloadLink.onload = () => {
          // Font is loaded
          this.isFontLoaded = true;
        };

        document.head.appendChild(preloadLink);
      });
    }
  }
};
</script>

<style scoped>

@font-face {
    font-family: 'european_teletext';
    src: url('../assets/fonts/europeanteletext-webfont.woff2') format('woff2'),
         url('../assets/fonts/europeanteletext-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    
}


.icon {
  width: 18px; /* Adjust icon size as needed */
  height: 18px;
  margin-right: 6px; /* Add some spacing between the icon and title text */
  vertical-align: middle
}

.icon-v {
  width: 28px;
  margin-right: 2px; /* Add some spacing between the icon and title text */
  vertical-align: bottom;
}

button.active {
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
}

.divider{
  width: 85%;
  height: 1px;
  border-top: 1px solid rgb(128, 128, 128);
  border-bottom: 1px solid rgb(255, 255, 255);
  margin: 20px auto;
}

.start-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 39px;
  border-top: 1px solid #fff;
  background-color: #c0c0c0;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 5px;
  font-family: "Pixelated MS Sans Serif",Arial;
  z-index: 9000;
}

.start-button {
  border: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    min-width: 40px;
    width: 75px;
}

.start-menu {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 255px;
  height: 500px;
  background-color: #c0c0c0;
  display: none;
}

.start-menu-body{
  display: flex;
  font-family: 'european_teletext';
  font-size: 14px;
}

.start-menu-titlebar {
    flex: 0 0 auto;
    width: 22px;
    background-color: #000080;
    height: 500px;
}

.start-menu-titlebar-img{
    position: relative;
    left: 2px;
    top: 316px;
}

.start-menu-items {
  flex: 1;
}


.start-menu-item.home {
  text-align: center;
  margin-top: 40px;
}


.menu-link {
  padding: 6px 4px;
}

.menu-link:hover {
  color: #fff;
  background-color:#000080;
  cursor: pointer;
}

.v-logo{
  padding: 5px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 95px;
}

.start-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.start-menu li {
  margin-bottom: 10px;
}

.start-menu li:last-child {
  margin-bottom: 0;
}

.start-menu {
  display: block;
}

.group-task{
  display: flex;
}

.taskbar-divider {
  width: 1px;
    height: 32px;
    background-color: #ccc;
    margin: 0 5px 0;
    border-left: 1px solid rgb(128, 128, 128);
    border-right: 1px solid rgb(255, 255, 255);
}

.task-icon{
  width: 16px; /* Adjust icon size as needed */
  height: 16px;
  margin-right: 2px; /* Add some spacing between the icon and title text */
  vertical-align: bottom;
}

.current-time {
  font-size: 11px; /* Adjust font size as needed */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 110px;
  text-align: center;
  box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
  margin-right: 7px;
}

</style>